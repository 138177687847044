import React from "react"
import classes from "../styles/about.module.css"
import image from "../images/mypicture.jpg"
import Layout from "../components/Layout/Layout"

const about = () => {
  return (
    <Layout>
      <div className={classes.main}>
        <div className={classes.main_left}>
          <div className={classes.photo}>
            <img src={image} alt="mypicture" />
          </div>
          <div className={classes.status}>
            <div className={classes.skills}>Skills</div>
            <div className={classes.skills_list}>
              HTML5 / CSS3 / Javascript / React.js / Gatsby.js
            </div>
            <div className={classes.likes}>Likes</div>
            <div className={classes.likes_list}>
              Skateboard/Comedy movies/Old Hip Hop
            </div>
          </div>
        </div>
        <div className={classes.main_right}>
          Hi! I'm Yusuke Yoshino. I'm Front-end Engineer based in Vancouver.I
          like designing.I take care of small things and details to make
          products work better and look beautiful.I hope you find something from
          my works.
        </div>
      </div>
    </Layout>
  )
}

export default about
